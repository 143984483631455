<template>
    <div>
        <div :style="{width: this.$oucy.pageWidth+'px', display: 'inline-block'}">
            <div class="screen">
                <div class="auth">
                    <span>认证</span>
                    <el-radio-group v-model="radio1" size="small" fill="#2090FF">
                        <el-radio-button label="全部"></el-radio-button>
                        <el-radio-button label="实名认证"></el-radio-button>
                        <el-radio-button label="信守约"></el-radio-button>
                        <el-radio-button label="品质厂商"></el-radio-button>
                    </el-radio-group>
                </div>
                <div class="auth">
                    <span>类型</span>
                  <el-radio-group v-model="radio2" size="small" fill="#2090FF">
                    <el-radio-button type="text" label="家具工厂"></el-radio-button>
                    <el-radio-button type="text" label="家具材料商"></el-radio-button>
                    <el-radio-button type="text" label="建材工厂"></el-radio-button>
                    <el-radio-button type="text" label="企业服务商"></el-radio-button>
                  </el-radio-group>
                </div>
                <div class="auth">
                    <span></span>
                  <el-radio-group v-model="radio3" size="small" fill="#2090FF">
                    <el-radio-button autofocus="true">全部</el-radio-button>
                    <el-radio-button label="软体家具"></el-radio-button>
                    <el-radio-button label="实木家具"></el-radio-button>
                    <el-radio-button label="板式家具"></el-radio-button>
                    <el-radio-button label="欧美家具"></el-radio-button>
                    <el-radio-button label="办公家具"></el-radio-button>
                    <el-radio-button label="酒店家具"></el-radio-button>
                    <el-radio-button label="五金家具"></el-radio-button>
                    <el-radio-button label="户外家具"></el-radio-button>
                    <el-radio-button label="智能家具"></el-radio-button>
                    <el-radio-button label="医疗养老家具"></el-radio-button>
                  </el-radio-group>
                </div>
            </div>

            <div class="sort">
                <div class="auth">
                    <span>排序</span>
                    <el-radio-group v-model="radio4" size="small" fill="#2090FF">
                      <el-radio-button label="最新入驻"></el-radio-button>
                      <el-radio-button label="最新发布"></el-radio-button>
                      <el-radio-button label="产品数量"></el-radio-button>
                      <el-radio-button label="最新登录"></el-radio-button>
                      <el-radio-button label="关注人气"></el-radio-button>
                      <el-radio-button label="浏览量"></el-radio-button>
                    </el-radio-group>
                </div>
            </div>

            <div class="middle">
                <ul>
                    <li v-for="(item,index) in list" :key="index">
                        <el-row>
                            <el-col :span="24">
                                <el-row>
                                    <el-col :span="7">
                                        <img class="com_head" src="../../../assets/images/com_logo.png">
                                    </el-col>

                                    <el-col :span="15" style="margin-left: 10px;">
                                        <el-row>
                                            <span>雅竹居家具</span>
                                        </el-row>
                                        <el-row>
                                            <div class="middle_card_star">
                                                <img src="../../../assets/images/star.png">
                                                <img src="../../../assets/images/star.png">
                                                <img src="../../../assets/images/star.png">
                                                <img src="../../../assets/images/star.png">
                                                <img src="../../../assets/images/star.png">
                                            </div>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-row>
                                <el-col :span="8">
                                    <div class="info">
                                        <p>关注人数</p>
                                        <p>8806</p>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="info">
                                        <p>产品数量</p>
                                        <p>112</p>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="info">
                                        <p>上次更新</p>
                                        <p>3小时前</p>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-top:15px;">
                                <el-col :span="8">
                                    <img src="../../../assets/images/sofa1.png" style="width:70px">
                                </el-col>
                                <el-col :span="8">
                                    <img src="../../../assets/images/sofa2.png" style="width:70px">
                                </el-col>
                                <el-col :span="8">
                                    <img src="../../../assets/images/sofa3.png" style="width:70px">
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <div class="goods_info">
                                        <p>磨砂科技棉纱</p>
                                        <p style="color:red;">￥2880</p>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="goods_info">
                                        <p>布艺沙发</p>
                                        <p style="font-size: 13px;color: #888888;">价格面议</p>
                                    </div>
                                </el-col>
                                <el-col :span="8">
                                    <div class="goods_info">
                                        <p>北欧乳胶布艺</p>
                                        <p style="color:red;">￥3650</p>
                                    </div>
                                </el-col>
                            </el-row>
                          </el-row>
                    </li>
                </ul>
            </div>

            <div class="pagination">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    prev-text="上一页"
                    next-text="下一页"
                    :total="1000">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'searchCom',
        data(){
            return{
                radio1: '全部',
                radio2: '家具工厂',
                radio3: '全部',
                radio4: '最新入驻',
                list:[1,2,3,4,5,6,7,8,9,10,11,12],
            }
        }
    }
</script>

<style scoped>
    .screen {
        width:100%;
        padding: 10px 0 30px 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        margin-top:20px;
    }

    .auth{
        display: flex;
        margin-top: 30px;
    }
    .auth >span{
        width:50px;
        font-size: 16px;
        font-weight: bold;
        color: #111111;
        margin-right:40px;
    }

    .auth .el-button--text{
        padding:0 10px 0 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
    }
    .auth /deep/ .el-radio-button__inner {
        border: none;
        border-radius: 3px;
        background: #F6F7FB;
        margin-left: 10px;
        text-align: left;
    }
    .el-button--text:hover{
        color: rgb(32,144,255) !important;
    }
    .el-button--mini{
        border:none;
    }
    .el-button--mini:hover {
        background-color: rgb(32,144,255) !important;
     }

    .el-button--text:focus{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2090FF;
    }

    .sort {
        width:100%;
        height:80px;
        padding-left:20px;
        box-sizing: border-box;
        background: #FFFFFF;
        margin-top:10px;
    }

    .middle{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .middle_card{
        width:290px;
        height:350px;
        background-color: #FFFFFF;
    }

    .com_head{
        width:80px;
        height:80px;
        border:1px rgb(243,243,243) solid;
        float:left;
    }
    .middle span{
        width:160px;
        font-size: 16px;
        font-weight: bold;
        color: #111111;
        float:left;
        display: flex;
        padding:25px 0 0 10px;
    }

    .middle_card_star{
      float:left;
      display: flex;
      margin-left:10px;
    }

    .info{
        width:70px;
        height:43px;
        background-color: rgb(248,248,249);
        padding:2px;
        margin:20px auto 0px;
    }
    .info p{
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 6px;
    }
    .middle_card_footer img{
        padding:20px 5px 0 5px;
    }
    .goods_info p{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        text-align: left;
        margin-left:7px;
    }
    .pagination{
        height:100px;
        margin-top:20px;
    }
    /deep/ .btn-next{width: 128px;height: 46px!important;background: #FFFFFF!important;}
    /deep/ .btn-prev{width: 128px;height: 46px!important;background: #FFFFFF!important;}
    /deep/ .el-pager{position: relative;top: 9px;}
    /deep/ .number{background: #FFFFFF!important;}
    /deep/ .active{background:#2090ff!important ;}

    .middle ul li:nth-child(4n){margin-right: 0px;}

    .middle ul li{
      width:252px;
      height:310px;
      background-color: #FFFFFF;
      padding:20px;
      float: left;
      margin: 0 50px 10px -40px;
      list-style: none;
    }
</style>

